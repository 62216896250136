<template>
    <div>
        <br>
        <h3 class="text-dark">Roles</h3>
        <br>
        <my-box>
            <b-button size="sm" variant="primary" squared class="mr-2" :to="'create-role'">Create</b-button>
        </my-box>
        <br>
        <div v-if="response.roles.length > 0">
            <my-box>
                <my-table borderless striped :items="response.roles" :fields="table.fields" @search="loadRoles" show-btn-ref>
                    <template v-slot:cell(visible)="data">
                        <span :class="data.item.visible == 'Visible' ? 'text-success' : 'text-danger'">{{data.item.visible}}</span>
                    </template>
                    <template v-slot:cell(type)="data">
                        <span :class="data.item.type == 'Variable' ? 'text-success' : 'text-danger'">{{data.item.type}}</span>
                    </template>
                    <template v-slot:cell(available)="data">
                        <span :class="data.item.available == 'Available' ? 'text-success' : 'text-danger'">{{data.item.available}}</span>
                    </template>
                </my-table>
            </my-box>
        </div>
        <div v-else>
            <my-loader title="Failed to load" :error-msg="errorMsg" :has-loaded="hasLoaded">
                <b-link variant="link" @click="() => {hasLoaded=false;loadRoles();}">Try Again</b-link>
            </my-loader>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorMsg: null,
            hasLoaded: false,
            response: {
                roles: []
            },
            table: {
                fields: [
                    {
                        key: 'name',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'available',
                        sortable: false,
                        filter: 'equal'
                    },
                    {
                        key: 'visible',
                        sortable: true,
                        filter: 'equal'
                    },
                    {
                        key: 'type',
                        sortable: true,
                        filter: 'equal'
                    },
                    {
                        key: 'activities',
                        sortable: false,
                        label: '',
                    },
                ]
            },
        };
    },
    methods: {
        loadRoles(filter) {
            this.$store.dispatch("credentials/role/get", { params: filter }).then(res => {
                this.response.roles = res.data.route
            }).catch(err => {
                this.errorMsg = error.status + ' - ' + error.statusText
            })
        }
    },
    created() {
        this.loadRoles()
    },
};
</script>